/* Position and sizing of burger button */
.bm-burger-button {
  display: flex;
  position: relative;
  margin-left: 10px;
  margin-top: 2px;
  width: 20px;
  height: 18px;
  z-index: 4 !important;

  @media screen and (min-width:768px) {
    margin-left: 15px;
  }

  @media screen and (min-width:1024px) {
    display: none;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #000000;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #000000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  width: 24px !important;
  height: 24px !important;
  top: 1.25rem !important;
  right: 25px !important;
  z-index: 10;

  @media screen and (min-width:768px) {
    top: 48px !important;
    right: 50px !important;
  }
}

.bm-cross-button > span {
  position: relative !important;
  display: block;
  height: 100%;
  width: 100%;
  top: 0 !important;
  right: 0 !important;
}

/* Color/shape of close button cross */
.bm-cross {
  height: 100% !important;
  top: 50%;
  left: 50%;
  margin: -50% 0 0;
  width: 2px !important;
  background: #fff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 100% !important;
  top: 0px;
  transition: transform 0.3s 0ms !important;

  @media screen and (min-width:768px) {
    width: 50% !important;
  }
}

/* General sidebar styles */
.bm-menu {
  background: #1A1A1A;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Individual item */
.bm-item {
}

/* Styling of overlay */
.bm-overlay {
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.6);
}
